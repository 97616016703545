import router from './router'
import { useAppStoreWithOut } from '@/store/modules/app'
import type { RouteRecordRaw } from 'vue-router'
import { useTitle } from '@/hooks/web/useTitle'
import { useNProgress } from '@/hooks/web/useNProgress'
import { usePermissionStoreWithOut } from '@/store/modules/permission'
import { usePageLoading } from '@/hooks/web/usePageLoading'
import { NO_REDIRECT_WHITE_LIST } from '@/constants'
import { useUserStoreWithOut } from '@/store/modules/user'
import { getAdminRoleApi, getTestRoleApi } from '@/api/login'

const { start, done } = useNProgress()

const { loadStart, loadDone } = usePageLoading()

router.beforeEach(async (to, from, next) => {
  start()
  loadStart()
  const permissionStore = usePermissionStoreWithOut()
  const appStore = useAppStoreWithOut()
  const userStore = useUserStoreWithOut()
  if (userStore.getToken) {
    if (to.path === '/login') {
      next({ path: '/' })
    } else {
      if (permissionStore.getIsAddRouters && userStore.getRoleRouters?.length) {
        console.log(permissionStore.getIsAddRouters)
        console.log('欢迎登录月兴充电！')
        next()
        return
      }
      try {
        const res = appStore.getDynamicRouter && appStore.getServerDynamicRouter ? await getAdminRoleApi() : await getTestRoleApi()
        if (res && res.data && res.data.length) {
          const routers = res.data || []
          userStore.setRoleRouters(routers)
          appStore.getDynamicRouter && appStore.getServerDynamicRouter
            ? await permissionStore.generateRoutes('server', routers).catch(() => {})
            : await permissionStore.generateRoutes('frontEnd', routers).catch(() => {})
  
          permissionStore.getAddRouters.forEach((route) => {
            router.addRoute(route as RouteRecordRaw) // 动态添加可访问路由表
          })
        } else {
          userStore.logout()
        }
        
        const redirectPath = from.query.redirect || to.path
        const redirect = decodeURIComponent(redirectPath as string)
        const nextData = to.path === redirect ? { ...to, replace: true } : { path: redirect }
        permissionStore.setIsAddRouters(true)
        next(nextData)
      } catch {
        userStore.logout()
      }
    }
  } else {
    if (NO_REDIRECT_WHITE_LIST.indexOf(to.path) !== -1) {
      next()
    } else {
      // next(`/login?redirect=${to.path}`) // 否则全部重定向到登录页
      next('/login') // 否则全部重定向到登录页
    }
  }
})

router.afterEach((to) => {
  useTitle(to?.meta?.title as string)
  done() // 结束Progress
  loadDone()
})
