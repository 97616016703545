import { createRouter, createWebHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import type { App } from 'vue'
import { Layout, getParentLayout } from '@/utils/routerHelper'
import { useI18n } from '@/hooks/web/useI18n'
import { NO_RESET_WHITE_LIST } from '@/constants'

const { t } = useI18n()

export const constantRouterMap: AppRouteRecordRaw[] = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    name: 'Dashboard',
    meta: {
      alwaysShow: false
    },
    children: [
      {
        path: 'home',
        component: () => import('@/views/Dashboard/index.vue'),
        name: 'Analysis',
        meta: {
          title: t('router.dashboard'),
          icon: 'ant-design:dashboard-filled',
          noCache: false,
          noTagsView: true,
          breadcrumb: false,
          affix: false
        }
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/Login/Login.vue'),
    name: 'Login',
    meta: {
      hidden: true,
      title: t('router.login'),
      noTagsView: true
    }
  },
  {
    path: '/404',
    component: () => import('@/views/Error/404.vue'),
    name: 'NoFind',
    meta: {
      hidden: true,
      title: '404',
      noTagsView: true
    }
  },
  {
    path: '/redirect',
    component: Layout,
    name: 'Redirect',
    children: [
      {
        path: '/redirect/:path(.*)',
        name: 'Redirect',
        component: () => import('@/views/Redirect/Redirect.vue'),
        meta: {}
      }
    ],
    meta: {
      hidden: true,
      noTagsView: true
    }
  }
]

export const asyncRouterMap: AppRouteRecordRaw[] = [
  // {
  //   path: '/dashboard',
  //   component: Layout,
  //   redirect: '/dashboard/analysis',
  //   name: 'Dashboard',
  //   meta: {
  //     title: t('router.dashboard'),
  //     icon: 'ant-design:dashboard-filled',
  //     alwaysShow: true
  //   },
  //   children: [
  //     {
  //       path: 'analysis',
  //       component: () => import('@/views/Dashboard/Analysis.vue'),
  //       name: 'Analysis',
  //       meta: {
  //         title: t('router.analysis'),
  //         noCache: true,
  //         affix: true
  //       }
  //     },
  //     {
  //       path: 'workplace',
  //       component: () => import('@/views/Dashboard/Workplace.vue'),
  //       name: 'Workplace',
  //       meta: {
  //         title: t('router.workplace'),
  //         noCache: true
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/authorization',
  //   component: Layout,
  //   redirect: '/authorization/menu',
  //   name: 'Authorization',
  //   alwaysShow: true,
  //   hidden: false,
  //   meta: {
  //     activeMenu: '',
  //     affix: true,
  //     alwaysShow: false,
  //     breadcrumb: false,
  //     icon: "system",
  //     link: null,
  //     noCache: false,
  //     noTagsView: true,
  //     title: "系统管理"
  //   },
  //   children: [
  //     {
  //       path: 'menu',
  //       hidden: false,
  //       component: () => import('@/views/Authorization/Menu/Menu.vue'),
  //       name: 'Menu',
  //       meta: {
  //         activeMenu: '',
  //         affix: true,
  //         alwaysShow: false,
  //         breadcrumb: false,
  //         icon: "system",
  //         link: null,
  //         noCache: false,
  //         noTagsView: true,
  //         title: "菜单管理"
  //       }
  //     }
  //   ]
  // }


  // {
  //   path: '/dashboard',
  //   component: Layout,
  //   redirect: '/dashboard/analysis',
  //   name: 'Dashboard',
  //   meta: {
  //     title: t('router.dashboard'),
  //     icon: 'ant-design:dashboard-filled',
  //     alwaysShow: true
  //   },
  //   children: [
  //     {
  //       path: 'analysis',
  //       component: () => import('@/views/Dashboard/Analysis.vue'),
  //       name: 'Analysis',
  //       meta: {
  //         title: t('router.analysis'),
  //         noCache: true,
  //         affix: true
  //       }
  //     },
  //     {
  //       path: 'workplace',
  //       component: () => import('@/views/Dashboard/Workplace.vue'),
  //       name: 'Workplace',
  //       meta: {
  //         title: t('router.workplace'),
  //         noCache: true
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/authorization',
  //   component: Layout,
  //   redirect: '/authorization/user',
  //   name: 'Authorization',
  //   meta: {
  //     title: t('router.authorization'),
  //     icon: 'eos-icons:role-binding',
  //     alwaysShow: true
  //   },
  //   children: [
  //     {
  //       path: 'menu',
  //       component: () => import('@/views/Authorization/Menu/Menu.vue'),
  //       name: 'Menu',
  //       meta: {
  //         title: t('router.menuManagement')
  //       }
  //     },
  //     {
  //       path: 'role',
  //       component: () => import('@/views/Authorization/Role/Role.vue'),
  //       name: 'Role',
  //       meta: {
  //         title: t('router.role')
  //       }
  //     }
  //   ]
  // },





  // {
  //   path: '/dashboard',
  //   component: Layout,
  //   redirect: '/dashboard/analysis',
  //   name: 'Dashboard',
  //   meta: {
  //     title: t('router.dashboard'),
  //     icon: 'ant-design:dashboard-filled',
  //     alwaysShow: true
  //   },
  //   children: [
  //     {
  //       path: 'analysis',
  //       component: () => import('@/views/Dashboard/Analysis.vue'),
  //       name: 'Analysis',
  //       meta: {
  //         title: t('router.analysis'),
  //         noCache: true,
  //         affix: true
  //       }
  //     },
  //     {
  //       path: 'workplace',
  //       component: () => import('@/views/Dashboard/Workplace.vue'),
  //       name: 'Workplace',
  //       meta: {
  //         title: t('router.workplace'),
  //         noCache: true
  //       }
  //     }
  //   ]
  // },
  
  // {
  //   path: '/device',
  //   component: Layout,
  //   redirect: '/device/automobile',
  //   name: 'device',
  //   meta: {
  //     title: t('router.device'),
  //     icon: 'ant-design:dashboard-filled',
  //     alwaysShow: true
  //   },
  //   children: [
  //     {
  //       path: 'automobile',
  //       component: () => import('@/views/device/automobile/index.vue'),
  //       name: 'DeviceAutomobile',
  //       meta: {
  //         title: t('router.automobileDevice')
  //       }
  //     },
  //     {
  //       path: 'bicycle',
  //       component: () => import('@/views/device/bicycle/index.vue'),
  //       name: 'DeviceBicycle',
  //       meta: {
  //         title: t('router.bicycleDevice')
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/charge',
  //   component: Layout,
  //   redirect: '/charge/automobile',
  //   name: 'charge',
  //   meta: {
  //     title: t('router.charge'),
  //     icon: 'ant-design:dashboard-filled',
  //     alwaysShow: true
  //   },
  //   children: [
  //     {
  //       path: 'automobile',
  //       component: () => import('@/views/charge/automobile/index.vue'),
  //       name: 'ChargeAutomobile',
  //       meta: {
  //         title: t('router.automobileCharge')
  //       }
  //     },
  //     {
  //       path: 'bicycle',
  //       component: () => import('@/views/charge/bicycle/index.vue'),
  //       name: 'ChargeBicycle',
  //       meta: {
  //         title: t('router.bicycleCharge')
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/operator',
  //   component: Layout,
  //   name: 'Operator',
  //   meta: {},
  //   children: [
  //     {
  //       path: 'operator-page',
  //       component: () => import('@/views/operator/index.vue'),
  //       name: 'operatorManagement',
  //       meta: {
  //         title: t('router.operatorManagement'),
  //         icon: 'cib:telegram-plane'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/station',
  //   component: Layout,
  //   name: 'station',
  //   meta: {},
  //   children: [
  //     {
  //       path: 'station-page',
  //       component: () => import('@/views/station/index.vue'),
  //       name: 'stationManagement',
  //       meta: {
  //         title: t('router.stationManagement'),
  //         icon: 'cib:telegram-plane'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/user',
  //   component: Layout,
  //   name: 'user',
  //   meta: {
  //     title: t('router.userManagement'),
  //     icon: 'ant-design:dashboard-filled',
  //     alwaysShow: true
  //   },
  //   children: [
  //     {
  //       path: 'userList',
  //       component: () => import('@/views/user/list.vue'),
  //       name: 'UserList',
  //       meta: {
  //         title: t('router.userList'),
  //         icon: 'cib:telegram-plane'
  //       }
  //     },
  //     {
  //       path: 'userDetail',
  //       component: () => import('@/views/user/user-details.vue'),
  //       name: 'UserDetail',
  //       meta: {
  //         title: t('router.userDetail'),
  //         noTagsView: true,
  //         noCache: true,
  //         hidden: true,
  //         canTo: true,
  //         activeMenu: '/user/userList'
  //       }
  //     },
  //     {
  //       path: 'blackAndWhite',
  //       component: () => import('@/views/user/blackAndWhite.vue'),
  //       name: 'BlackAndWhite',
  //       meta: {
  //         title: t('router.blackAndWhite'),
  //         icon: 'cib:telegram-plane'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/ICCard',
  //   component: Layout,
  //   name: 'ICCard',
  //   meta: {
  //     title: t('router.ICCardManagement'),
  //     icon: 'ant-design:dashboard-filled',
  //     alwaysShow: true
  //   },
  //   children: [
  //     {
  //       path: 'card-page',
  //       component: () => import('@/views/ICCard/index.vue'),
  //       name: 'ICCardPage',
  //       meta: {
  //         title: t('router.ICCardList'),
  //         icon: 'cib:telegram-plane'
  //       },
  //       children: [
  //         {
  //           path: 'list',
  //           component: () => import('@/views/ICCard/list.vue'),
  //           name: 'ICCardList',
  //           meta: {
  //             title: t('router.ICCardList'),
  //             noTagsView: true,
  //             noCache: true,
  //             hidden: true,
  //             canTo: true,
  //             activeMenu: '/ICCard/card-page'
  //           }
  //         }
  //       ]
  //     },
  //     {
  //       path: 'replacement',
  //       component: () => import('@/views/ICCard/replacement.vue'),
  //       name: 'ICCardReplacement',
  //       meta: {
  //         title: t('router.replacement'),
  //         icon: 'cib:telegram-plane'
  //       }
  //     },
  //     {
  //       path: 'black',
  //       component: () => import('@/views/ICCard/black.vue'),
  //       name: 'BlackList',
  //       meta: {
  //         title: t('router.blackList'),
  //         icon: 'cib:telegram-plane'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/company',
  //   component: Layout,
  //   name: 'company',
  //   meta: {
  //     title: t('router.companyBusiness'),
  //     icon: 'ant-design:dashboard-filled',
  //     alwaysShow: true
  //   },
  //   children: [
  //     {
  //       path: 'companylist',
  //       component: () => import('@/views/company/companyList/index.vue'),
  //       name: 'CompanyList',
  //       meta: {
  //         title: t('router.companyList'),
  //         icon: 'cib:telegram-plane'
  //       }
  //     },
  //     {
  //       path: 'stafflist',
  //       component: () => import('@/views/company/userList/index.vue'),
  //       name: 'Stafflist',
  //       meta: {
  //         title: t('router.staffManagement'),
  //         icon: 'cib:telegram-plane'
  //       }
  //     },
  //     {
  //       path: 'carlist',
  //       component: () => import('@/views/company/carList/index.vue'),
  //       name: 'CarList',
  //       meta: {
  //         title: t('router.vehicleManagement'),
  //         icon: 'cib:telegram-plane'
  //       }
  //     },
  //     {
  //       path: 'fundlist',
  //       component: () => import('@/views/company/fundList/index.vue'),
  //       name: 'FundList',
  //       meta: {
  //         title: t('router.fundManagement'),
  //         icon: 'cib:telegram-plane'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/marketing',
  //   component: Layout,
  //   name: 'marketing',
  //   meta: {
  //     title: t('router.marketing'),
  //     icon: 'ant-design:dashboard-filled',
  //     alwaysShow: true
  //   },
  //   children: [
  //     {
  //       path: 'activity',
  //       component: () => import('@/views/marketing/activity/index.vue'),
  //       name: 'MarketingActivity',
  //       meta: {
  //         title: t('router.marketingActivity'),
  //         icon: 'cib:telegram-plane'
  //       }
  //     },
  //     {
  //       path: 'car',
  //       component: () => import('@/views/marketing/car/index.vue'),
  //       name: 'MarketingCar',
  //       meta: {
  //         title: t('router.marketingCar'),
  //         icon: 'cib:telegram-plane'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/order',
  //   component: Layout,
  //   name: 'order',
  //   meta: {
  //     title: t('router.orderManagement'),
  //     icon: 'ant-design:dashboard-filled',
  //     alwaysShow: true
  //   },
  //   children: [
  //     {
  //       path: 'charge',
  //       component: () => import('@/views/order/charge/index.vue'),
  //       name: 'OrderCharge',
  //       meta: {
  //         title: t('router.orderCharge'),
  //         icon: 'cib:telegram-plane'
  //       }
  //     },
  //     {
  //       path: 'charge-detail',
  //       component: () => import('@/views/order/charge/detail.vue'),
  //       name: 'OrderChargeDetail',
  //       meta: {
  //         title: t('router.orderRechargeDetail'),
  //         noTagsView: true,
  //         noCache: true,
  //         hidden: true,
  //         canTo: true,
  //         activeMenu: '/order/charge'
  //       }
  //     },
  //     {
  //       path: 'charge-bike',
  //       component: () => import('@/views/order/charge/bike.vue'),
  //       name: 'OrderChargeBikeDetail',
  //       meta: {
  //         title: t('router.orderRechargeDetail'),
  //         noTagsView: true,
  //         noCache: true,
  //         hidden: true,
  //         canTo: true,
  //         activeMenu: '/order/charge'
  //       }
  //     },
  //     {
  //       path: 'recharge',
  //       component: () => import('@/views/order/recharge/index.vue'),
  //       name: 'OrderRecharge',
  //       meta: {
  //         title: t('router.orderRecharge'),
  //         icon: 'cib:telegram-plane'
  //       }
  //     },
  //     {
  //       path: 'icCard',
  //       component: () => import('@/views/order/icCard/index.vue'),
  //       name: 'OrderIcCard',
  //       meta: {
  //         title: t('router.orderIcCard'),
  //         icon: 'cib:telegram-plane'
  //       }
  //     },
  //     {
  //       path: 'virtually',
  //       component: () => import('@/views/order/virtually/index.vue'),
  //       name: 'OrderVirtually',
  //       meta: {
  //         title: t('router.orderVirtually'),
  //         icon: 'cib:telegram-plane'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/financial',
  //   component: Layout,
  //   name: 'financial',
  //   meta: {
  //     title: t('router.financialManagement'),
  //     icon: 'ant-design:dashboard-filled',
  //     alwaysShow: true
  //   },
  //   children: [
  //     {
  //       path: 'revenue',
  //       component: () => import('@/views/financial/revenue/index.vue'),
  //       name: 'FinancialRevenue',
  //       meta: {
  //         title: t('router.financialRevenue'),
  //         icon: 'cib:telegram-plane'
  //       }
  //     },
  //     {
  //       path: 'icRecharge',
  //       component: () => import('@/views/financial/recharge/index.vue'),
  //       name: 'financialRecharge',
  //       meta: {
  //         title: t('router.financialRerecharge'),
  //         icon: 'cib:telegram-plane'
  //       }
  //     },
  //     {
  //       path: 'monthlyRecharge',
  //       component: () => import('@/views/financial/recharge/index.vue'),
  //       name: 'financialMonthly',
  //       meta: {
  //         title: t('router.financialMonthly'),
  //         icon: 'cib:telegram-plane'
  //       }
  //     },
  //     {
  //       path: 'withdrawal',
  //       component: () => import('@/views/financial/withdrawal/index.vue'),
  //       name: 'FinancialWithdrawal',
  //       meta: {
  //         title: t('router.financialWithdrawal'),
  //         icon: 'cib:telegram-plane'
  //       }
  //     },
  //     {
  //       path: 'refund',
  //       component: () => import('@/views/financial/refund/index.vue'),
  //       name: 'FinancialRefund',
  //       meta: {
  //         title: t('router.financialRefund'),
  //         icon: 'cib:telegram-plane'
  //       }
  //     },
  //     {
  //       path: 'changes',
  //       component: () => import('@/views/financial/changes/index.vue'),
  //       name: 'FinancialChanges',
  //       meta: {
  //         title: t('router.financialChanges'),
  //         icon: 'cib:telegram-plane'
  //       }
  //     },
  //     {
  //       path: 'accounts',
  //       component: () => import('@/views/financial/accounts/index.vue'),
  //       name: 'FinancialAccounts',
  //       meta: {
  //         title: t('router.financialAccounts'),
  //         icon: 'cib:telegram-plane'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/merchant',
  //   component: Layout,
  //   name: 'merchant',
  //   meta: {},
  //   children: [
  //     {
  //       path: 'merchant-page',
  //       component: () => import('@/views/Merchant/index.vue'),
  //       name: 'merchantManagement',
  //       meta: {
  //         title: t('router.merchantManagement'),
  //         icon: 'cib:telegram-plane'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/research',
  //   component: Layout,
  //   name: 'research',
  //   meta: {
  //     title: t('router.researchDevelopment'),
  //     icon: 'ant-design:dashboard-filled',
  //     alwaysShow: true
  //   },
  //   children: [
  //     {
  //       path: 'debugging',
  //       component: () => import('@/views/research/debugging/index.vue'),
  //       name: 'researchDebugging',
  //       meta: {
  //         title: t('router.researchDebugging'),
  //         icon: 'cib:telegram-plane'
  //       }
  //     },
  //     {
  //       path: 'upgrade',
  //       component: () => import('@/views/research/upgrade/index.vue'),
  //       name: 'researchUpgrade',
  //       meta: {
  //         title: t('router.researchUpgrade'),
  //         icon: 'cib:telegram-plane'
  //       }
  //     },
  //     {
  //       path: 'upgrade-device',
  //       component: () => import('@/views/research/upgrade/device.vue'),
  //       name: 'ResearchUpgradeDevice',
  //       meta: {
  //         title: t('router.researchUpgradeDevice'),
  //         noTagsView: true,
  //         noCache: true,
  //         hidden: true,
  //         canTo: true,
  //         activeMenu: '/research/upgrade'
  //       }
  //     },
  //     {
  //       path: 'record',
  //       component: () => import('@/views/research/record/index.vue'),
  //       name: 'researchRecord',
  //       meta: {
  //         title: t('router.researchRecord'),
  //         icon: 'cib:telegram-plane'
  //       }
  //     },
  //     {
  //       path: 'log',
  //       component: () => import('@/views/research/log/index.vue'),
  //       name: 'researchLog',
  //       meta: {
  //         title: t('router.researchLog'),
  //         icon: 'cib:telegram-plane'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/platform',
  //   component: Layout,
  //   name: 'platform',
  //   meta: {},
  //   children: [
  //     {
  //       path: 'platform',
  //       component: () => import('@/views/platform/index.vue'),
  //       name: 'PlatformManagement',
  //       meta: {
  //         title: t('router.platformManagement'),
  //         icon: 'cib:telegram-plane'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/maintenance',
  //   component: Layout,
  //   name: 'maintenance',
  //   meta: {
  //     title: t('router.maintenanceServices'),
  //     icon: 'ant-design:dashboard-filled',
  //     alwaysShow: true
  //   },
  //   children: [
  //     {
  //       path: 'workOrder',
  //       component: () => import('@/views/maintenance/workOrder/index.vue'),
  //       name: 'maintenanceWorkOrder',
  //       meta: {
  //         title: t('router.maintenanceWorkOrder'),
  //         icon: 'cib:telegram-plane'
  //       }
  //     },
  //     {
  //       path: 'faultReport',
  //       component: () => import('@/views/maintenance/faultReport/index.vue'),
  //       name: 'maintenanceFaultReport',
  //       meta: {
  //         title: t('router.maintenanceFaultReport'),
  //         icon: 'cib:telegram-plane'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/cooperate',
  //   component: Layout,
  //   name: 'Cooperation',
  //   meta: {
  //     title: t('router.cooperation'),
  //     icon: 'ant-design:dashboard-filled',
  //     alwaysShow: true
  //   },
  //   children: [
  //     {
  //       path: 'information',
  //       component: () => import('@/views/cooperate/information/index.vue'),
  //       name: 'CooperateInformation',
  //       meta: {
  //         title: t('router.cooperateInformation'),
  //         icon: 'cib:telegram-plane'
  //       }
  //     },
  //     {
  //       path: 'installation',
  //       component: () => import('@/views/cooperate/installation/index.vue'),
  //       name: 'CooperateInstallation',
  //       meta: {
  //         title: t('router.cooperateInstallation'),
  //         icon: 'cib:telegram-plane'
  //       }
  //     },
  //     {
  //       path: 'devices',
  //       component: () => import('@/views/cooperate/devices/index.vue'),
  //       name: 'CooperateDevices',
  //       meta: {
  //         title: t('router.cooperateDevices'),
  //         icon: 'cib:telegram-plane'
  //       }
  //     },
  //     {
  //       path: 'abnormal',
  //       component: () => import('@/views/cooperate/abnormal/index.vue'),
  //       name: 'CooperateAbnormal',
  //       meta: {
  //         title: t('router.cooperateAbnormal'),
  //         icon: 'cib:telegram-plane'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/authorization',
  //   component: Layout,
  //   redirect: '/authorization/user',
  //   name: 'Authorization',
  //   meta: {
  //     title: t('router.authorization'),
  //     icon: 'eos-icons:role-binding',
  //     alwaysShow: true
  //   },
  //   children: [
  //     {
  //       path: 'department',
  //       component: () => import('@/views/Authorization/Department/Department.vue'),
  //       name: 'Department',
  //       meta: {
  //         title: t('router.department')
  //       }
  //     },
  //     {
  //       path: 'user',
  //       component: () => import('@/views/Authorization/User/User.vue'),
  //       name: 'User',
  //       meta: {
  //         title: t('router.user')
  //       }
  //     },
  //     {
  //       path: 'menu',
  //       component: () => import('@/views/Authorization/Menu/Menu.vue'),
  //       name: 'Menu',
  //       meta: {
  //         title: t('router.menuManagement')
  //       }
  //     },
  //     {
  //       path: 'role',
  //       component: () => import('@/views/Authorization/Role/Role.vue'),
  //       name: 'Role',
  //       meta: {
  //         title: t('router.role')
  //       }
  //     },
  //     {
  //       path: 'dictionary',
  //       component: () => import('@/views/Authorization/Dictionary/Dictionary.vue'),
  //       name: 'Department',
  //       meta: {
  //         title: t('router.department')
  //       }
  //     },
  //   ]
  // },

  
  // {
  //   path: '/dashboard',
  //   component: Layout,
  //   redirect: '/dashboard/analysis',
  //   name: 'Dashboard',
  //   meta: {
  //     title: t('router.dashboard'),
  //     icon: 'ant-design:dashboard-filled',
  //     alwaysShow: true
  //   },
  //   children: [
  //     {
  //       path: 'analysis',
  //       component: () => import('@/views/Dashboard/Analysis.vue'),
  //       name: 'Analysis',
  //       meta: {
  //         title: t('router.analysis'),
  //         noCache: true,
  //         affix: true
  //       }
  //     },
  //     {
  //       path: 'workplace',
  //       component: () => import('@/views/Dashboard/Workplace.vue'),
  //       name: 'Workplace',
  //       meta: {
  //         title: t('router.workplace'),
  //         noCache: true
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/external-link',
  //   component: Layout,
  //   meta: {},
  //   name: 'ExternalLink',
  //   children: [
  //     {
  //       path: 'https://element-plus-admin-doc.cn/',
  //       name: 'DocumentLink',
  //       meta: {
  //         title: t('router.document'),
  //         icon: 'clarity:document-solid'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/guide',
  //   component: Layout,
  //   name: 'Guide',
  //   meta: {},
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import('@/views/Guide/Guide.vue'),
  //       name: 'GuideDemo',
  //       meta: {
  //         title: t('router.guide'),
  //         icon: 'cib:telegram-plane'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/components',
  //   component: Layout,
  //   name: 'ComponentsDemo',
  //   meta: {
  //     title: t('router.component'),
  //     icon: 'bx:bxs-component',
  //     alwaysShow: true
  //   },
  //   children: [
  //     {
  //       path: 'form',
  //       component: getParentLayout(),
  //       redirect: '/components/form/default-form',
  //       name: 'Form',
  //       meta: {
  //         title: t('router.form'),
  //         alwaysShow: true
  //       },
  //       children: [
  //         {
  //           path: 'default-form',
  //           component: () => import('@/views/Components/Form/DefaultForm.vue'),
  //           name: 'DefaultForm',
  //           meta: {
  //             title: t('router.defaultForm')
  //           }
  //         },
  //         {
  //           path: 'use-form',
  //           component: () => import('@/views/Components/Form/UseFormDemo.vue'),
  //           name: 'UseForm',
  //           meta: {
  //             title: 'UseForm'
  //           }
  //         }
  //       ]
  //     },
  //     {
  //       path: 'table',
  //       component: getParentLayout(),
  //       redirect: '/components/table/default-table',
  //       name: 'TableDemo',
  //       meta: {
  //         title: t('router.table'),
  //         alwaysShow: true
  //       },
  //       children: [
  //         {
  //           path: 'default-table',
  //           component: () => import('@/views/Components/Table/DefaultTable.vue'),
  //           name: 'DefaultTable',
  //           meta: {
  //             title: t('router.defaultTable')
  //           }
  //         },
  //         {
  //           path: 'use-table',
  //           component: () => import('@/views/Components/Table/UseTableDemo.vue'),
  //           name: 'UseTable',
  //           meta: {
  //             title: 'UseTable'
  //           }
  //         },
  //         {
  //           path: 'tree-table',
  //           component: () => import('@/views/Components/Table/TreeTable.vue'),
  //           name: 'TreeTable',
  //           meta: {
  //             title: t('router.treeTable')
  //           }
  //         },
  //         {
  //           path: 'table-image-preview',
  //           component: () => import('@/views/Components/Table/TableImagePreview.vue'),
  //           name: 'TableImagePreview',
  //           meta: {
  //             title: t('router.PicturePreview')
  //           }
  //         },
  //         {
  //           path: 'table-video-preview',
  //           component: () => import('@/views/Components/Table/TableVideoPreview.vue'),
  //           name: 'TableVideoPreview',
  //           meta: {
  //             title: t('router.tableVideoPreview')
  //           }
  //         },
  //         {
  //           path: 'card-table',
  //           component: () => import('@/views/Components/Table/CardTable.vue'),
  //           name: 'CardTable',
  //           meta: {
  //             title: t('router.cardTable')
  //           }
  //         }
  //       ]
  //     },
  //     {
  //       path: 'editor-demo',
  //       component: getParentLayout(),
  //       redirect: '/components/editor-demo/editor',
  //       name: 'EditorDemo',
  //       meta: {
  //         title: t('router.editor'),
  //         alwaysShow: true
  //       },
  //       children: [
  //         {
  //           path: 'editor',
  //           component: () => import('@/views/Components/Editor/Editor.vue'),
  //           name: 'Editor',
  //           meta: {
  //             title: t('router.richText')
  //           }
  //         },
  //         {
  //           path: 'json-editor',
  //           component: () => import('@/views/Components/Editor/JsonEditor.vue'),
  //           name: 'JsonEditor',
  //           meta: {
  //             title: t('router.jsonEditor')
  //           }
  //         }
  //       ]
  //     },
  //     {
  //       path: 'search',
  //       component: () => import('@/views/Components/Search.vue'),
  //       name: 'Search',
  //       meta: {
  //         title: t('router.search')
  //       }
  //     },
  //     {
  //       path: 'descriptions',
  //       component: () => import('@/views/Components/Descriptions.vue'),
  //       name: 'Descriptions',
  //       meta: {
  //         title: t('router.descriptions')
  //       }
  //     },
  //     {
  //       path: 'image-viewer',
  //       component: () => import('@/views/Components/ImageViewer.vue'),
  //       name: 'ImageViewer',
  //       meta: {
  //         title: t('router.imageViewer')
  //       }
  //     },
  //     {
  //       path: 'dialog',
  //       component: () => import('@/views/Components/Dialog.vue'),
  //       name: 'Dialog',
  //       meta: {
  //         title: t('router.dialog')
  //       }
  //     },
  //     {
  //       path: 'icon',
  //       component: () => import('@/views/Components/Icon.vue'),
  //       name: 'Icon',
  //       meta: {
  //         title: t('router.icon')
  //       }
  //     },
  //     {
  //       path: 'icon-picker',
  //       component: () => import('@/views/Components/IconPicker.vue'),
  //       name: 'IconPicker',
  //       meta: {
  //         title: t('router.iconPicker')
  //       }
  //     },
  //     {
  //       path: 'echart',
  //       component: () => import('@/views/Components/Echart.vue'),
  //       name: 'Echart',
  //       meta: {
  //         title: t('router.echart')
  //       }
  //     },
  //     {
  //       path: 'count-to',
  //       component: () => import('@/views/Components/CountTo.vue'),
  //       name: 'CountTo',
  //       meta: {
  //         title: t('router.countTo')
  //       }
  //     },
  //     {
  //       path: 'qrcode',
  //       component: () => import('@/views/Components/Qrcode.vue'),
  //       name: 'Qrcode',
  //       meta: {
  //         title: t('router.qrcode')
  //       }
  //     },
  //     {
  //       path: 'highlight',
  //       component: () => import('@/views/Components/Highlight.vue'),
  //       name: 'Highlight',
  //       meta: {
  //         title: t('router.highlight')
  //       }
  //     },
  //     {
  //       path: 'infotip',
  //       component: () => import('@/views/Components/Infotip.vue'),
  //       name: 'Infotip',
  //       meta: {
  //         title: t('router.infotip')
  //       }
  //     },
  //     {
  //       path: 'input-password',
  //       component: () => import('@/views/Components/InputPassword.vue'),
  //       name: 'InputPassword',
  //       meta: {
  //         title: t('router.inputPassword')
  //       }
  //     },
  //     {
  //       path: 'waterfall',
  //       component: () => import('@/views/Components/Waterfall.vue'),
  //       name: 'waterfall',
  //       meta: {
  //         title: t('router.waterfall')
  //       }
  //     },
  //     {
  //       path: 'image-cropping',
  //       component: () => import('@/views/Components/ImageCropping.vue'),
  //       name: 'ImageCropping',
  //       meta: {
  //         title: t('router.imageCropping')
  //       }
  //     },
  //     {
  //       path: 'video-player',
  //       component: () => import('@/views/Components/VideoPlayer.vue'),
  //       name: 'VideoPlayer',
  //       meta: {
  //         title: t('router.videoPlayer')
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/function',
  //   component: Layout,
  //   redirect: '/function/multipleTabs',
  //   name: 'Function',
  //   meta: {
  //     title: t('router.function'),
  //     icon: 'ri:function-fill',
  //     alwaysShow: true
  //   },
  //   children: [
  //     {
  //       path: 'multiple-tabs',
  //       component: () => import('@/views/Function/MultipleTabs.vue'),
  //       name: 'MultipleTabs',
  //       meta: {
  //         title: t('router.multipleTabs')
  //       }
  //     },
  //     {
  //       path: 'multiple-tabs-demo/:id',
  //       component: () => import('@/views/Function/MultipleTabsDemo.vue'),
  //       name: 'MultipleTabsDemo',
  //       meta: {
  //         hidden: true,
  //         title: t('router.details'),
  //         canTo: true,
  //         activeMenu: '/function/multiple-tabs'
  //       }
  //     },
  //     {
  //       path: 'request',
  //       component: () => import('@/views/Function/Request.vue'),
  //       name: 'Request',
  //       meta: {
  //         title: t('router.request')
  //       }
  //     },
  //     {
  //       path: 'test',
  //       component: () => import('@/views/Function/Test.vue'),
  //       name: 'Test',
  //       meta: {
  //         title: t('router.permission'),
  //         permission: ['add', 'edit', 'delete']
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/hooks',
  //   component: Layout,
  //   redirect: '/hooks/useWatermark',
  //   name: 'Hooks',
  //   meta: {
  //     title: 'hooks',
  //     icon: 'ic:outline-webhook',
  //     alwaysShow: true
  //   },
  //   children: [
  //     {
  //       path: 'useWatermark',
  //       component: () => import('@/views/hooks/useWatermark.vue'),
  //       name: 'UseWatermark',
  //       meta: {
  //         title: 'useWatermark'
  //       }
  //     },
  //     {
  //       path: 'useTagsView',
  //       component: () => import('@/views/hooks/useTagsView.vue'),
  //       name: 'UseTagsView',
  //       meta: {
  //         title: 'useTagsView'
  //       }
  //     },
  //     {
  //       path: 'useValidator',
  //       component: () => import('@/views/hooks/useValidator.vue'),
  //       name: 'UseValidator',
  //       meta: {
  //         title: 'useValidator'
  //       }
  //     },
  //     {
  //       path: 'useCrudSchemas',
  //       component: () => import('@/views/hooks/useCrudSchemas.vue'),
  //       name: 'UseCrudSchemas',
  //       meta: {
  //         title: 'useCrudSchemas'
  //       }
  //     },
  //     {
  //       path: 'useClipboard',
  //       component: () => import('@/views/hooks/useClipboard.vue'),
  //       name: 'UseClipboard',
  //       meta: {
  //         title: 'useClipboard'
  //       }
  //     },
  //     {
  //       path: 'useNetwork',
  //       component: () => import('@/views/hooks/useNetwork.vue'),
  //       name: 'UseNetwork',
  //       meta: {
  //         title: 'useNetwork'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/level',
  //   component: Layout,
  //   redirect: '/level/menu1/menu1-1/menu1-1-1',
  //   name: 'Level',
  //   meta: {
  //     title: t('router.level'),
  //     icon: 'carbon:skill-level-advanced'
  //   },
  //   children: [
  //     {
  //       path: 'menu1',
  //       name: 'Menu1',
  //       component: getParentLayout(),
  //       redirect: '/level/menu1/menu1-1/menu1-1-1',
  //       meta: {
  //         title: t('router.menu1')
  //       },
  //       children: [
  //         {
  //           path: 'menu1-1',
  //           name: 'Menu11',
  //           component: getParentLayout(),
  //           redirect: '/level/menu1/menu1-1/menu1-1-1',
  //           meta: {
  //             title: t('router.menu11'),
  //             alwaysShow: true
  //           },
  //           children: [
  //             {
  //               path: 'menu1-1-1',
  //               name: 'Menu111',
  //               component: () => import('@/views/Level/Menu111.vue'),
  //               meta: {
  //                 title: t('router.menu111')
  //               }
  //             }
  //           ]
  //         },
  //         {
  //           path: 'menu1-2',
  //           name: 'Menu12',
  //           component: () => import('@/views/Level/Menu12.vue'),
  //           meta: {
  //             title: t('router.menu12')
  //           }
  //         }
  //       ]
  //     },
  //     {
  //       path: 'menu2',
  //       name: 'Menu2',
  //       component: () => import('@/views/Level/Menu2.vue'),
  //       meta: {
  //         title: t('router.menu2')
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/example',
  //   component: Layout,
  //   redirect: '/example/example-dialog',
  //   name: 'Example',
  //   meta: {
  //     title: t('router.example'),
  //     icon: 'ep:management',
  //     alwaysShow: true
  //   },
  //   children: [
  //     {
  //       path: 'example-dialog',
  //       component: () => import('@/views/Example/Dialog/ExampleDialog.vue'),
  //       name: 'ExampleDialog',
  //       meta: {
  //         title: t('router.exampleDialog')
  //       }
  //     },
  //     {
  //       path: 'example-page',
  //       component: () => import('@/views/Example/Page/ExamplePage.vue'),
  //       name: 'ExamplePage',
  //       meta: {
  //         title: t('router.examplePage')
  //       }
  //     },
  //     {
  //       path: 'example-add',
  //       component: () => import('@/views/Example/Page/ExampleAdd.vue'),
  //       name: 'ExampleAdd',
  //       meta: {
  //         title: t('router.exampleAdd'),
  //         noTagsView: true,
  //         noCache: true,
  //         hidden: true,
  //         canTo: true,
  //         activeMenu: '/example/example-page'
  //       }
  //     },
  //     {
  //       path: 'example-edit',
  //       component: () => import('@/views/Example/Page/ExampleEdit.vue'),
  //       name: 'ExampleEdit',
  //       meta: {
  //         title: t('router.exampleEdit'),
  //         noTagsView: true,
  //         noCache: true,
  //         hidden: true,
  //         canTo: true,
  //         activeMenu: '/example/example-page'
  //       }
  //     },
  //     {
  //       path: 'example-detail',
  //       component: () => import('@/views/Example/Page/ExampleDetail.vue'),
  //       name: 'ExampleDetail',
  //       meta: {
  //         title: t('router.exampleDetail'),
  //         noTagsView: true,
  //         noCache: true,
  //         hidden: true,
  //         canTo: true,
  //         activeMenu: '/example/example-page'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/error',
  //   component: Layout,
  //   redirect: '/error/404',
  //   name: 'Error',
  //   meta: {
  //     title: t('router.errorPage'),
  //     icon: 'ci:error',
  //     alwaysShow: true
  //   },
  //   children: [
  //     {
  //       path: '404-demo',
  //       component: () => import('@/views/Error/404.vue'),
  //       name: '404Demo',
  //       meta: {
  //         title: '404'
  //       }
  //     },
  //     {
  //       path: '403-demo',
  //       component: () => import('@/views/Error/403.vue'),
  //       name: '403Demo',
  //       meta: {
  //         title: '403'
  //       }
  //     },
  //     {
  //       path: '500-demo',
  //       component: () => import('@/views/Error/500.vue'),
  //       name: '500Demo',
  //       meta: {
  //         title: '500'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/authorization',
  //   component: Layout,
  //   redirect: '/authorization/user',
  //   name: 'Authorization',
  //   meta: {
  //     title: t('router.authorization'),
  //     icon: 'eos-icons:role-binding',
  //     alwaysShow: true
  //   },
  //   children: [
  //     {
  //       path: 'department',
  //       component: () => import('@/views/Authorization/Department/Department.vue'),
  //       name: 'Department',
  //       meta: {
  //         title: t('router.department')
  //       }
  //     },
  //     {
  //       path: 'user',
  //       component: () => import('@/views/Authorization/User/User.vue'),
  //       name: 'User',
  //       meta: {
  //         title: t('router.user')
  //       }
  //     },
  //     {
  //       path: 'menu',
  //       component: () => import('@/views/Authorization/Menu/Menu.vue'),
  //       name: 'Menu',
  //       meta: {
  //         title: t('router.menuManagement')
  //       }
  //     },
  //     {
  //       path: 'role',
  //       component: () => import('@/views/Authorization/Role/Role.vue'),
  //       name: 'Role',
  //       meta: {
  //         title: t('router.role')
  //       }
  //     }
  //   ]
  // }
]

const router = createRouter({
  history: createWebHistory(),
  strict: true,
  routes: constantRouterMap as RouteRecordRaw[],
  scrollBehavior: () => ({ left: 0, top: 0 })
})

export const resetRouter = (): void => {
  router.getRoutes().forEach((route) => {
    const { name } = route
    if (name && !NO_RESET_WHITE_LIST.includes(name as string)) {
      router.hasRoute(name) && router.removeRoute(name)
    }
  })
}

export const setupRouter = (app: App<Element>) => {
  app.use(router)
}

export default router
