import request from '@/axios'
import type { UserType } from './types'

export const loginApi = (data: UserType): Promise<IResponse<UserType>> => {
  return request.post({ url: '/system/auth/login', data, headers: {'Access-Control-Allow-Origin': '*'} })
}

export const loginCodeApi = (): Promise<IResponse> => {
  return request.get({ url: '/system/getImageCode' })
}

export const loginOutApi = (): Promise<IResponse> => {
  return request.get({ url: '/mock/user/loginOut' })
}

export const getUserListApi = ({ params }: AxiosConfig) => {
  return request.get<{
    code: string
    data: {
      list: UserType[]
      total: number
    }
  }>({ url: '/mock/user/list', params })
}

export const getRoleApi = (): Promise<IResponse> => {
  return request.get({ url: '/system/user/getInfo' })
}

export const getAdminRoleApi = (): Promise<IResponse<AppCustomRouteRecordRaw[]>> => {
  return request.get({ url: '/system/menu/getRouters' })
}

export const getTestRoleApi = (): Promise<IResponse<string[]>> => {
  return request.get({ url: '/mock/role/list2' })
}
